// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dash-callback-dag--container {
    border-radius: 4px;
    position: fixed;
    bottom: 165px;
    right: 16px;
    width: 80vw;
    height: calc(100vh - 180px);
    overflow: auto;
    box-sizing: border-box;
    background: #ffffff;
    display: inline-block;
    /* shadow-1 */
    box-shadow: 0px 6px 16px rgba(80, 103, 132, 0.165),
        0px 2px 6px rgba(80, 103, 132, 0.12),
        0px 0px 1px rgba(80, 103, 132, 0.32);
}

.dash-callback-dag--info {
    border-radius: 4px;
    position: absolute;
    padding: 8px;
    bottom: 16px;
    left: 16px;
    max-width: calc(100% - 32px);
    max-height: 50%;
    overflow: auto;
    box-sizing: border-box;
    background: rgba(255,255,255,0.9);
    border: 2px solid #ccc;
    font-family: "Arial", sans-serif;
}

.dash-callback-dag--message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 2em;
    font-family: "Arial", sans-serif;
}

.dash-callback-dag--layoutSelector {
    position: absolute;
    top: 10px;
    right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/error/CallbackGraph/CallbackGraphContainer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,WAAW;IACX,WAAW;IACX,2BAA2B;IAC3B,cAAc;IACd,sBAAsB;IACtB,mBAAmB;IACnB,qBAAqB;IACrB,aAAa;IACb;;4CAEwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,4BAA4B;IAC5B,eAAe;IACf,cAAc;IACd,sBAAsB;IACtB,iCAAiC;IACjC,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":[".dash-callback-dag--container {\n    border-radius: 4px;\n    position: fixed;\n    bottom: 165px;\n    right: 16px;\n    width: 80vw;\n    height: calc(100vh - 180px);\n    overflow: auto;\n    box-sizing: border-box;\n    background: #ffffff;\n    display: inline-block;\n    /* shadow-1 */\n    box-shadow: 0px 6px 16px rgba(80, 103, 132, 0.165),\n        0px 2px 6px rgba(80, 103, 132, 0.12),\n        0px 0px 1px rgba(80, 103, 132, 0.32);\n}\n\n.dash-callback-dag--info {\n    border-radius: 4px;\n    position: absolute;\n    padding: 8px;\n    bottom: 16px;\n    left: 16px;\n    max-width: calc(100% - 32px);\n    max-height: 50%;\n    overflow: auto;\n    box-sizing: border-box;\n    background: rgba(255,255,255,0.9);\n    border: 2px solid #ccc;\n    font-family: \"Arial\", sans-serif;\n}\n\n.dash-callback-dag--message {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    line-height: 2em;\n    font-family: \"Arial\", sans-serif;\n}\n\n.dash-callback-dag--layoutSelector {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
