// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._dash-undo-redo {
    position: fixed;
    bottom: 30px;
    left: 30px;
    font-size: 20px;
    text-align: center;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.9);

}
._dash-undo-redo>div {
    position: relative;
}
._dash-undo-redo-link {
    color: #0074D9;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    opacity: 0.2;
}
._dash-undo-redo-link:hover {
    opacity: 1;
}
._dash-undo-redo-link ._dash-icon-undo {
    font-size: 20px;
    transform: rotate(270deg);
}
._dash-undo-redo-link ._dash-icon-redo {
    font-size: 20px;
    transform: rotate(90deg);
}
._dash-undo-redo-link ._dash-undo-redo-label {
    font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/core/Toolbar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,UAAU;IACV,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,0CAA0C;;AAE9C;AACA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;AAChB;AACA;IACI,UAAU;AACd;AACA;IACI,eAAe;IACf,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,wBAAwB;AAC5B;AACA;IACI,eAAe;AACnB","sourcesContent":["._dash-undo-redo {\n    position: fixed;\n    bottom: 30px;\n    left: 30px;\n    font-size: 20px;\n    text-align: center;\n    z-index: 9999;\n    background-color: rgba(255, 255, 255, 0.9);\n\n}\n._dash-undo-redo>div {\n    position: relative;\n}\n._dash-undo-redo-link {\n    color: #0074D9;\n    cursor: pointer;\n    margin-left: 10px;\n    margin-right: 10px;\n    display: inline-block;\n    opacity: 0.2;\n}\n._dash-undo-redo-link:hover {\n    opacity: 1;\n}\n._dash-undo-redo-link ._dash-icon-undo {\n    font-size: 20px;\n    transform: rotate(270deg);\n}\n._dash-undo-redo-link ._dash-icon-redo {\n    font-size: 20px;\n    transform: rotate(90deg);\n}\n._dash-undo-redo-link ._dash-undo-redo-label {\n    font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
