// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.percy-show {
    display: none;
}

@media only percy {
    .percy-hide {
        display: none;
    }
    .percy-show {
        display: block;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/error/Percy.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".percy-show {\n    display: none;\n}\n\n@media only percy {\n    .percy-hide {\n        display: none;\n    }\n    .percy-show {\n        display: block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
